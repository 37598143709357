<template>
  <page-container
    is-show-breadcrumbs
    style="padding-bottom: 24px"
    class="custom-el-style"
  >
    <el-header>创建数据模板</el-header>
    <el-main>
      <el-form
        :model="formData"
        ref="formData"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="企业前缀" :label-width="labelWidth">
          {{ formData.tpl_node }}
        </el-form-item>
        <el-form-item label="行业门类" :label-width="labelWidth">
          {{ INDUSTRY_CATEGORY }}
        </el-form-item>
        <el-form-item label="行业大类" :label-width="labelWidth">
          {{ INDUSTRY_SPECIFIC }}
        </el-form-item>
        <el-form-item
          label="行业中类"
          :label-width="labelWidth"
          prop="tpl_trade"
        >
          <span v-if="isCopy">{{ trade }}</span>
          <el-select
            v-else
            v-model="trade"
            @change="getNextCategory"
            placeholder="请选择行业中类"
          >
            <el-option
              v-for="item in categoryList"
              :key="item.dict_id"
              :label="item.dict_value"
              :value="item.dict_code"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="行业小类"
          :label-width="labelWidth"
          prop="tpl_subclass"
        >
          <span v-if="isCopy">{{ subclass }}</span>
          <el-select
            v-else
            v-model="subclass"
            @change="getSubCategory"
            placeholder="请选择行业小类"
          >
            <el-option
              v-for="item in subCategoryList"
              :key="item.dict_id"
              :label="item.dict_value"
              :value="item.dict_code"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="模板名称"
          :label-width="labelWidth"
          prop="tpl_name"
        >
          <el-input style="width: 300px" v-model="formData.tpl_name" />
        </el-form-item>
        <el-form-item label="模板内容" :label-width="labelWidth">
          <el-button type="primary" plain @click="handleAdd">
            添加模板内容
          </el-button>
        </el-form-item>
        <div v-for="(item, index) in formData.items" :key="index">
          <el-form-item
            :label="'模板内容' + (index + 1)"
            :label-width="labelWidth"
          />
          <el-form-item label="中文名称" label-width="200px" :prop="'items.'+ index +'.attr_name'"
                        :rules="[
                          { required: true , message: '请选择填写中文名称', trigger: 'blur' },
                        ]"
          >
            <span v-if="length > index">{{ item.attr_name }}</span>
            <el-input v-else style="width: 300px" v-model="item.attr_name" />
            <el-button v-if="index" style="margin-left: 20px;" @click="handleDelete(index)" type="danger"
                       plain
            >
              删 除
            </el-button>
          </el-form-item>
          <el-form-item
            label="是否必填"
            label-width="200px"
            :prop="'items.'+ index +'.attr_required'"
            :rules="[
              { required: true , message: '请选择是否必须', trigger: 'change' },
            ]"
          >
            <span v-if="length > index">{{
              item.attr_required ? "是" : "否"
            }}</span>
            <el-radio-group v-else v-model="item.attr_required">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item label-width="120px">
          <el-button type="primary" @click="submit">保存模板</el-button>
        </el-form-item>
      </el-form>
    </el-main>
  </page-container>
</template>
<script>
import PageContainer from "@/components/common/PageContainer.vue";
import { companyApi, templateApi } from "@/services/api";
export default {
  name: "ShareHolderList",
  components: {
    PageContainer
  },
  data() {
    return {
      trade: "",
      subclass: "",
      length: "",
      INDUSTRY_CATEGORY: "",
      INDUSTRY_SPECIFIC: "",
      formData: {
        tpl_name: "",
        tpl_node: "",
        tpl_category: "",
        tpl_specific: "",
        tpl_trade: "",
        tpl_subclass: "",
        items: []
      },
      isCopy: false,
      categoryList: [],
      subCategoryList: [],
      labelWidth: "120px",
      rules: {
        tpl_trade: [
          { required: true, message: "请选择行业中类", trigger: "blur" }
        ],
        tpl_subclass: [
          { required: true, message: "请选择行业小类", trigger: "change" }
        ],
        tpl_name: [
          { required: true, message: "请输入模板名称", trigger: "change" }
        ]
      }
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    async getData() {
      console.log();
      let state = this.$store.state;
      let id = this.$route.query.tpl_id;
      if (id) {
        let { data } = await templateApi.detail({ id });
        console.log(data);
        this.formData = {
          tpl_name: data.tpl_name,
          tpl_node: "",
          tpl_category: data.tpl_category,
          tpl_specific: data.tpl_specific,
          tpl_trade: data.tpl_trade,
          tpl_subclass: data.tpl_subclass,
          items: data.items
        };
        this.isCopy = true;
        this.length = data.items.length;
      } else {
        this.formData.tpl_category = state.entBasicInfo.industryCategoryId;
        this.formData.tpl_specific = state.entBasicInfo.industrySpecificId;
      }
      this.formData.tpl_node = state.company_node;
      this.INDUSTRY_CATEGORY = state.entBasicInfo.industryCategory;
      this.INDUSTRY_SPECIFIC = state.entBasicInfo.industrySpecific;
      this.getCategory(state.entBasicInfo.industrySpecificId);
    },
    async getCategory(code) {
      let { data } = await companyApi.category({
        dict_key: "INDUSTRY_CATEGORY",
        code,
        is_child: true
      });
      if (this.formData.tpl_trade) {
        let tradeData = data.filter(
          item => item.dict_code == this.formData.tpl_trade
        );
        let subclassData = tradeData[0].children.filter(
          item => item.dict_code == this.formData.tpl_subclass
        );
        this.trade = tradeData[0].dict_value;
        this.subclass = subclassData[0].dict_value;
      }
      this.categoryList = data;
    },
    async getNextCategory(code) {
      let { data } = await companyApi.category({
        dict_key: "INDUSTRY_SUBCLASS",
        code,
        is_child: true
      });
      this.formData.tpl_trade = code;
      this.subCategoryList = data;
    },
    getSubCategory(code) {
      this.formData.tpl_subclass = code;
    },
    handleAdd() {
      let data = {
        attr_name: "",
        attr_required: ""
      };
      this.formData.items.push(data);
    },
    handleDelete(index) {
      this.formData.items.splice(index, 1);
    },
    submit() {
      this.$refs["formData"].validate(async valid => {
        if (valid) {
          console.log(this.formData);
          let { code, message } = await templateApi.save(this.formData);
          console.log(code);
          if (code != 200) {
            this.$message.error(message);
          } else {
            this.$message.success("添加成功！");
            this.$router.push("/manage/patternplate");
          }
        } else {
          this.$message.warning("请完整的填写必填信息");
          return false;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
// /deep/.el-input .el-input__inner {
//   width: 50% !important;
// }
/deep/.el-radio {
  margin-right: 100px;
}
.el-form {
  /deep/.el-select .el-input {
    width: 300px !important;
  }
  /deep/ .el-button.el-button--primary {
    min-width: 80px;
  }
}
</style>
